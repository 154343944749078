.all-products-page {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px;
  }
  
  /* Conteneur pour l'image et le texte */
  .all-products-image-text-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .all-products-image-column {
    flex: 1;
    max-width: 75%; /* La colonne d'image occupe 75% de la largeur */
    height: 350px; /* Ajustez selon la hauteur souhaitée */
    position: relative;
    overflow: hidden; /* Empêche l'image de dépasser */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10%; /* Permet à l'image de dépasser les marges de l'écran */
  }
  
  .all-products-image {
    width: auto;
    height: 120%; /* L'image dépasse légèrement la hauteur définie */
    object-fit: cover; /* S'assure que l'image remplit bien le conteneur */
    transform: rotate(90deg); /* Pivote l'image à 90 degrés */
  }
  
  .all-products-text-column {
    flex: 1;
    max-width: 25%; /* Le texte occupe 25% de la largeur */
    padding: 1rem;
}
  
  .all-products-text-column h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .all-products-text-column p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .product-list2 {
    display: grid; /* Activer CSS Grid */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Colonnes réactives avec minimum 200px par colonne */
    gap: 12px; /* Espacement entre les éléments */
    
  }
  @media (max-width: 448px) {
    .product-list2 {
     
      grid-template-columns: repeat(2, 1fr); /* 2 colonnes au minimum */    
    }
    .product-list2 .product-list {
      display: block; /* Annule le grid */
  }
  
    .product-item:nth-child(2n) {
      border-right: none !important; /* Supprimer la bordure du dernier élément sur chaque ligne */
    }
  }