/* QuantitySelector.css */

.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon-button {
    border: 1px solid #28a745; /* Bordure verte */
    background-color: transparent; /* Pas de fond par défaut */
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .icon-button:hover {
    background-color: #e6f4ea; /* Fond vert pâle au survol */
  }
  
  .icon {
    width: 18px;
    height: 18px;
    color: #28a745;
  }
  
  .quantity-box {
    display: flex;
    align-items: center;
    border: 1px solid #28a745;
    border-radius: 40px;
    
    background-color: transparent; /* Pas de fond */
  }
  
  .quantity-display {
    margin: 0 1rem;
    font-size: 1.2rem;
    color: #28a745; /* Texte vert */
  }
  
  .increment-button,
  .decrement-button,
  .remove-button {
    border: none; /* Pas de bordure pour les icônes à l'intérieur */
    background-color: transparent; /* Pas de fond pour les boutons à l'intérieur */
  }
  
  .increment-button:hover,
  .decrement-button:hover,
  .remove-button:hover {
    background-color: transparent; /* Pas de fond au survol à l'intérieur */
  }
  .trash-icon {
    width: 18px;
    height: 18px;
  }
  .quantity-indicator {
    background-color: green;
    color: white;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    
  }
  
  .icon-button.toggle-button.quantity-greater-than-1 {
    border: none; /* Assure que la bordure est supprimée */
    background: transparent; /* Rendre le fond transparent */
  }