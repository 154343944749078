.modal-container {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  .modal-tab-bar {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .modal-tab {
    padding: 10px 20px;
    font-size: 16px;
    color: #666;
    border: none;
    background: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  
  .modal-tab:hover {
    color: #28a745;
  }
  
  .active-tab {
    color: #28a745;
    font-weight: bold;
    border-color: #28a745;
  }
  
  .modal-content {
    font-size: 16px;
    line-height: 1.5;
  }
  