/* Conteneur principal du menu déroulant */


.tabs {
  display: flex;
  justify-content: center; /* Centre les labels dans leur espace */
  margin-bottom: 20px;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.active-tab {
  font-weight: bold;
  color: #007bff;
}
.categories-grid {
  display: flex;
  justify-content:space-evenly; 
  
}

.category-column {
  
  display: flex;
  flex-direction: column;
  text-align: left;
  
}



.category-link {
  display: block;
  padding: 8px 0;
  text-decoration: none;
  font-size: 14px;
  color: #555;
  transition: color 0.3s ease;
}

.category-link:hover {
  color: var(--primary-color);
}


.breadcrumb {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.breadcrumb span {
  font-weight: bold;
}

/* Responsiveness pour adapter la grille selon la taille de l'écran */
@media (max-width: 1200px) {
  .dropdown-container {
    width: calc(100% - 30px); /* Ajuste la largeur en fonction de la taille de l'écran */
    margin: 0 15px;
  }
}



/* Affichage du dropdown lorsqu'on survole l'élément parent */
.nav-item:hover .dropdown-container {
  display: block; /* Affiche le dropdown au survol */
  z-index: 1000; /* Placer le dropdown au-dessus de l'élément parent */
}

/* Ajustements pour mobile */
@media (max-width: 768px) {
  .dropdown-container {
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  .categories-grid {
    flex-direction: column;
  }

  .category-column {
    width: 100%;
  }
}
