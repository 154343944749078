.bread {
    background-color: transparent; /* Fond transparent */
    margin-top: 20px; /* Espace au-dessus */
    border-bottom: 1px solid black; /* Bord inférieur */
    padding: 50px 0; /* Espacement interne */
    text-align: left; /* Alignement à gauche */
}

.breadcrumb-item {
    display: inline; /* Affichage en ligne */
    font-size: 24px;
   
}

.breadcrumb-item a { 
    text-decoration: none; /* Retirer le soulignement des liens */
    
    color: black; /* Couleur par défaut */
}

.breadcrumb-item a:hover {
    color: #21691a; /* Couleur au survol */
}

.breadcrumb-item + .breadcrumb-item:before {
    content: '/'; /* Remplacer la flèche par une barre oblique */
    padding: 0 8px; /* Espacement autour de la barre oblique */
}

.breadcrumb-item.active {
    font-weight: bold; /* Mettre en gras l'élément actif */
    color: #21691a; /* Couleur pour l'élément actif */
}
.Breadcrumbs_counter__hUeTs {
    color: #333;; /* Couleur grise pour le texte */
    font-weight: 400; /* Poids de la police pour le texte */
   
  }
  