div.product-detail-breadcrumb > .bread {
    border-bottom: none !important;
    padding: 0;
  }
  

  .product-detail-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  
  .related-products-section {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  
  .related-products-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .related-products-grid {
    margin-top: 20px;
  }
  
  .related-products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }