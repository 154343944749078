:root {
    /* Variables de couleurs */
    --primary-color: #21691a;
    --secondary-color: darkgray;
    --blue-hover: #3b82f6;
    --border-color: #d1d5db;
    --background-light: #fafafa;
    --background-overlay: rgba(0, 0, 0, 0.7);
    --font-color-dark: #1f2937;
    --font-color-light: #697488;
}

/* ----- Modal Styles ----- */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-overlay);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto; /* Ajoutez cette ligne pour permettre le défilement sur l'ensemble de la fenêtre */
    padding: 20px; /* Optionnel: ajouter du padding pour éviter que le contenu soit trop proche des bords */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    position: relative;
    max-height: 90vh; /* Limite la hauteur de la modale à 90% de la hauteur de la fenêtre */
    overflow-y: auto; /* Permet de faire défiler le contenu si la hauteur dépasse */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Nécessaire pour garder le positionnement correct */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}

.close-icon {
    cursor: pointer;
}

.close-icon-image {
    width: 24px;
    height: 24px;
}

.modal-header {
    display: flex;
    justify-content: space-between; /* Positionne le logo à gauche et l'icône de fermeture à droite */
    align-items: center; /* Aligne verticalement le logo et l'icône */
    padding: 10px; /* Ajustez selon vos besoins */
}

/* ----- Toggle Styles ----- */
.Toggle_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 7px 8px;
    border-radius: 40px;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.Toggle_option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.Toggle_icon {
    min-height: 1rem;
    min-width: 1rem;
    margin: .25rem .5rem 0 0;
}

.Toggle_toggle_label {
    font-family: var(--font-circular-std, sans-serif);
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.3;
    display: flex;
    align-items: center;
}

.Toggle_label {
    border-radius: 20px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Toggle_label:hover {
    background-color: #e0e0e0;
}

.Toggle_label.active {
    background-color: #e0e0e0;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

/* ----- Button Styles ----- */
.create-account-button {
    background: none;
    border: none;
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
    padding: 8px 16px;
    transition: color 0.3s ease;
}

.create-account-button:hover {
    color: var(--blue-hover);
}

.SignInEmail_submit_button_wrapper {
    margin-top: 20px;
}

.SignInEmail_submit_button_wrapper button {
    width: 100%;
    padding: 15px;
    border-radius: 40px;
    border: none;
    background-color: var(--secondary-color);
    color: white;
    font-size: 16px;
    cursor: not-allowed;
    transition: background-color 0.3s;
}

.SignInEmail_submit_button_wrapper button:enabled {
    background-color: var(--primary-color);
    cursor: pointer;
}

/* ----- Checkbox and Label Styles ----- */
.signIn_field {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkbox {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: #f3f4f6;
    outline: none;
    transition: transform 0.2s ease;
}

.checkbox:hover {
    transform: scale(1.1);
}

.checkbox:focus {
    border-color: var(--blue-hover);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.checkbox-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--font-color-dark);
    transition: color 0.2s, transform 0.2s ease;
}

.checkbox:hover + .checkbox-label,
.checkbox-label:hover {
    color: var(--blue-hover);
    transform: scale(1.05);
}

/* ----- Input Styles ----- */
.SignInEmail_field, .SignInPhone_field {
    position: relative;
    margin-bottom: 20px;
    
}

.SignInEmail_field input, .SignInPhone_field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 40px;
    outline: none;
    transition: border-color 0.2s;
}

.SignInEmail_field input:focus, .SignInPhone_field input:focus {
    border-color: var(--blue-hover);
}

.SignInEmail_field label, .SignInPhone_field label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    background-color: white;
    padding: 0 5px;
}

.SignInEmail_field input:focus + label,
.SignInPhone_field input:focus + label,
.SignInEmail_field input:not(:placeholder-shown) + label,
.SignInPhone_field input:not(:placeholder-shown) + label {
    top: -5px;
}

/* ----- Divider Styles ----- */
.custom-divider {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.custom-divider::before,
.custom-divider::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}

.custom-divider::before {
    margin-right: 10px;
}

.custom-divider::after {
    margin-left: 10px;
}

.custom-divider span {
    font-family: var(--font-circular-std, sans-serif);
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.3;
    color: var(--font-color-light);
    background: var(--background-light);
    padding: 0 10px;
}
