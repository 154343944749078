/* Conteneur principal */
.popular-products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  /* Aligne les éléments en haut pour éviter que la colonne d'image ne soit plus haute que la colonne de texte */

}

/* Section contenant les produits et les informations textuelles */
.content-section {
  width: 60%;
  /* Section avec les produits et le texte */
  display: flex;
  flex-direction: column;

  gap: 8px;

}

/* Colonne de texte */
.text-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Conteneur de la liste des produits */
.product-list-column {
  flex: 1;
  display: flex;
}
.border-right {
  margin-left: 10px;
  border-right: 1px solid #ddd; /* Bordure légère à droite */
}
/* Colonne contenant l'image */
.image-column {
  flex: 1;
  position: relative;
  
  /* S'assure que la colonne d'image prenne toute la hauteur disponible */
}

/* Image dans la colonne */
.product-Image {
  width: 100%;
  /* Couvre toute la largeur de la colonne */

  /* Couvre toute la hauteur de la colonne */
  margin-left: 20px;
  object-fit: cover;
  /* L'image couvre toute la colonne sans déformation */
  object-position: center;
  /* Centre l'image à l'intérieur du conteneur */
  display: block;
  /* Évite les espaces blancs sous l'image */
}

.product-navigation {
  display: flex;
  align-items: center;
  /* Aligne verticalement les éléments */
  gap: 10px;
  /* Ajoute un espacement entre le texte et les icônes */
  margin-top: 10px;
  /* Espace au-dessus des icônes de navigation */
}



/* Style des icônes (flèches) */
.prev-button:disabled,
.next-button:disabled {
  color: #ccc;
  /* Couleur des icônes désactivées */
  pointer-events: none;
}


.product-list1 {
  display: grid;
  /* Activer CSS Grid */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Colonnes réactives avec minimum 300px par colonne */
  gap: 12px;
  /* Espacement entre les éléments */
}



@media (max-width: 768px) {
  
  .product-list1 {
    display: none;
  }
  .product-navigation {
    display: none !important;
    /* Cache les boutons de navigation sur les petits écrans */
  }
  .view-button {
    font-size: 14px;
    color: #298321;
  
  }

 

}

@media (min-width: 768px) {
  .view-button {
    display: none;
  
  }

  
}

.navigation-and-description {
  display: flex;
  align-items: center;
  /* Aligne le texte et les boutons verticalement */
  justify-content: space-between;
  /* Espace entre le paragraphe et les boutons */
}

.product-navigation {
  display: flex;
  gap: 8px;
  /* Espace entre les boutons */
}
.list{
  flex: 1 1;
    display: flex
;
}

.prev-button,
.next-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  /* Couleur des icônes */
}

.popular-products-container.border-top {
  margin-top: 40px;
  border-top: 1px solid #e5e5e5;
  /* Ajout de la bordure en haut */
  padding-top: 20px;
  /* Optionnel : Ajoute un peu d'espace au-dessus du conteneur */
}