/* Global CSS */
:root {
    --white: #ffffff;
    --light-gray: #fafafa;
    --gray: #4a4a4a;
    --dark-gray: #333333;
    --medium-gray: #666666;
    --blue: #007bff;
    --blue-hover: #0056b3;
    --border-gray: #ccc;
    --border-light-gray: #D1D5DB;
    --background-gray: #f8f9fa;
    --vert:#509E2F;

}


body {
    font-size: var(--body);
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 18px;
}

p {
    font-size: 14px;
}

u {
    font-size: 14px;
}

.nav-link,
.navbar-brand,
.header,
.container-fluid,
.navbar-toggler-icon {
    font-size: 14px;
}

/* Pour les écrans de taille supérieure ou égale à 992px */
@media (min-width: 992px) {
    body {
      margin-left: 40px;
      margin-right: 40px;
    }
  }


  @media (max-width: 768px) {
    body {
        padding-left: 10px;
        padding-right: 10px;
    }
}

  
.flex {
    display: flex;
}





/* Border styling */
.border-l {
    border-left-width: 1px;
    border-left-color: var(--border-light-gray);
    border-left-style: solid;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
}
