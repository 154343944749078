.departments-section {
  width: 100%;
  padding: 0 20px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.main-department-title {
  font-size: 48px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

.main-department-header {
  width: 50%;
  text-align: left;
}

.section-divider {
  width: 100%;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.departments-row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
  gap: 20px;
  margin-bottom: 20px;
  align-items: stretch; /* Assure que toutes les sections ont la même hauteur */
}

.department-section {
  flex: 1 1 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Uniformise la hauteur des sections */
  box-sizing: border-box;
}

.department-section:not(:last-child) {
  border-right: 1px solid #ccc;
}

.department-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.categories-container {
  display: flex;
  gap: 10px;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 70%;
}

.category-item {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}

.view-all-button {
  font-size: 14px;
  color: #298321;
  text-decoration: none;
}

.department-image {
  width: 80px; /* Spécifie une largeur fixe */
  height: auto; /* Conserve les proportions */
  align-self: flex-end; /* Positionne l'image à l'extrémité */
}

@media (max-width: 992px) {
  .departments-row {
    flex-direction: column;
    align-items: stretch;
  }

  .department-section {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}

@media (max-width: 576px) {
  .main-department-title {
    font-size: 36px;
  }
  
  .department-image {
    width: 60px; /* Taille plus petite pour les écrans mobiles */
  }

  .view-all-button {
    font-size: 12px;
  }
}
