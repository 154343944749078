/* Footer container and layout */
.footer-container {
  width: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  box-sizing: border-box;
}

.footer-content {
  display: grid; /* Utilisation de grid pour gérer les colonnes */
  gap: 20px; /* Espacement entre les colonnes */
  width: 100%;
  padding: 20px 0;
}

/* Footer columns default (4 columns on large screens) */
.footer-column {
  padding: 10px;
  text-align: left;
}

.footer-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-link-item {
  margin-bottom: 10px;
}

.footer-link-item a {
  color: #333;
  text-decoration: none;
  display: block;
  text-align: left;
  width: 100%;
}

.footer-link-item a:hover {
  color: #007bff;
}

/* Styling for images in links */
.footer-link-image {
  max-width: 100px;
  margin: 0 auto;
  display: block;
}

.footer-divider {
  border: 1px solid #ccc;
  margin: 20px 0;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.footer-bottom-text {
  margin-left: 10px;
}

.footer-bottom-links a {
  margin-left: 10px;
  color: #333;
  text-decoration: none;
}

.footer-bottom-links a:hover {
  color: #007bff;
}

.back-to-top {
  width: 100%;
  height: 45px;
  position: relative;
  background-color: #0000;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 1px solid #888;
  border-radius: 40px;
  color: #444;
  cursor: pointer;
  display: none; /* Par défaut, le bouton est caché */
}

.back-to-top.show {
  display: block;
}

/* Footer layout for large screens (4 columns) */
@media (min-width: 993px) {
  .footer-content {
    grid-template-columns: repeat(4, 1fr); /* 4 colonnes pour les grands écrans */
  }
}

/* Footer layout for small screens (2 columns) */
@media (max-width: 992px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes pour les petits écrans */
  }
}

