/* Header - Navigation principale */
.header {
  width: 100%; /* S'assure que l'en-tête prend toute la largeur */
  margin-bottom: 0;
 
}

.dynamic-dropdown {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  pointer-events: auto;
}


/* Disposition des éléments de la barre de navigation */
.navbar-nav {
  display: flex;
  justify-content: space-evenly; /* Répartit les éléments de manière égale */
  align-items: center;
  list-style-type: none;
  
  width: 100%; /* S'assure que le conteneur occupe toute la largeur */
  box-sizing: border-box;
}

/* Style pour chaque élément de navigation */
.nav-item {
 padding: 15px;
  position: relative!important; /* Pour les sous-menus */
  text-align: center;
}

/* Menu global (flexible) */
.navbar-menu {
  display: flex;
  justify-content: space-between; /* Pour espacer les éléments du menu */
  width: 100%; /* Remplir toute la largeur */
 
  box-sizing: border-box; /* Inclut les marges internes dans la largeur totale */
  overflow: hidden;
}

/* Lien du menu avec une disposition en colonne */
.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
  width: 100%; /* Remplir toute la largeur de l'élément de menu */
}

.nav-link:hover {
  background-color: #f8f9fa;
  color: black;
}

/* Image de l'icône dans les liens */
.nav-link img {
  height: 22px;
  width: 22px;
  margin-bottom: 0;
}

/* Conteneur pour le label et le bouton */
.label-button-container {
  display: flex;
  align-items: center;
 
  white-space: nowrap;
}

/* Chevron pour dropdown */
.chevron-icon {
  transition: transform 0.3s;
}

.GlobalNav_nav_item_caret.open .chevron-icon {
  transform: rotate(180deg);
}

/* Icône du chevron */
.GlobalNav_nav_item_caret {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

/* Section de livraison */
.delivery-section {
  display: flex;
  align-items: center;
}


.delivery-icon {
  margin-left: 30px;
  background-color: white;
  width: 30px;
  height: 30px; /* Assurez-vous que la hauteur est égale à la largeur */
  border-radius: 50%; /* Cette propriété rendra l'élément rond */
}


.TopLine_delivery {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.TopLine_delivery_title {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

/* --- Bouton d'adresse sous le texte "Livraison" --- */
.Address_link {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
  color: white;
}

/* Responsive pour petits écrans (moins de 992px) */
@media (max-width: 992px) {

  /* Cacher le menu par défaut */
  .navbar-menu {
    display: none;
  }

  /* Affichage du menu en popup */
  .navbar-menu.show {
   
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Hauteur de l'écran */
    background-color: #21691a;
    z-index: 1000;
    overflow-y: auto;
  }

  /* Classe pour désactiver le scroll du body */
  body.no-scroll {
    overflow: hidden;
  }

  /* Popup de menu de compte */
  .account-popup-menu {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 13px;
    color: white;
    border-top: 1px solid white;
    background-color: #21691a;
    width: 100%;
    z-index: 10;
    overflow: hidden;
  }

  .account-icon {
    margin-right: 8px;
    fill: white !important;
  }

  .account-link {
    background: none;
    border: none;
    color: white !important;
    font-size: 18px;
    cursor: pointer;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 32px;
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1001;
  }

  /* Style des icônes et du texte en blanc */
  .nav-link img,
  .bi-chevron-down {
    filter: brightness(0) invert(1);
    width: 30px;
    height: 30px;
  }

  /* Réduire la marge pour les éléments du menu */
  .navbar-nav {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-item {
    margin:0 10px;
    position: relative!important; /* Pour les sous-menus */
    text-align: center;
  }

  .label-button-container {
    font-size: 18px;
    margin-left: 30px;
  }

  /* Réduction de l'espace entre l'icône et le texte */
  .nav-link {
    /*padding: 5px;*/
    flex-direction: row;
    align-items: center;
    color: white;
  }

  .nav-link:hover {
    background-color: #21691a;
    color: white;
  }
}

/* Navbar */
.navbar-top {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Équilibre les éléments */
}

.search-bar-container {
  width: 100%;
  margin-top: 10px;
}

.cart-button-container {
  margin-left: auto; /* Positionne le panier à l'extrémité droite */
  display: flex;
  align-items: center;
}

/* Ajustements pour petits écrans */
@media (max-width: 992px) {
  .GlobalNav_nav_item_caret{
    display: none;
  }
  .container-fluid {
    margin-left: 10px;
    margin-right: 10px;
  }

  .navbar-top {
    align-items: center;
  }

  .search-bar-container {
    margin-top: 20px;
  }

  .navbar-menu {
   
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 993px) {
  .navbar-nav {

    justify-content: center; /* Centrer les éléments sur grand écran */
  }
  .nav-item {
    position: relative; /* Nécessaire pour que le dropdown soit aligné */
  }
}

/* Assurez-vous que le menu est visible */
.navbar-menu.show {
  display: block;
}
