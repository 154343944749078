.breadcrumb {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de revenir à la ligne */
    gap: 8px; /* Espacement entre les éléments */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    
    color: black; /* Couleur des liens */
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: inherit;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline; /* Effet survol pour les liens */
  }
  
  .breadcrumb-item.active {
    color: #21691a; /* Couleur pour l'élément actif */
  }
  