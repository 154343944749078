/* --- Conteneur principal pour occuper toute la largeur avec marges --- */
.TopLine_layout_start {
    width: 100%;
    height: 80px;
    
    /* Limite la largeur maximale */

    /*padding: 0 16px;*/
    /* Marges sur les côtés */
    box-sizing: border-box;
    display: flex;




}

/* Flexibilité pour répartir les éléments uniformément et espacement */
.TopLine_layout_start .flex {
    display: flex;
    align-items: center;

    width: 100%;
}

/* --- Logo et livraison --- */
.TopLine_logo_wrapper {
    display: flex;

    align-items: center;
}

.TopLine_logo {
    width: 100px;

}

.TopLine_logo_image {
    width: 100px;

}



.Address_container {
    display: flex;
    flex-direction: column;
    /* Aligner verticalement */
    align-items: flex-start;
    /* Aligner à gauche */
}

/* --- Barre de recherche --- */
.TopLine_search_bar {

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchbar-form {
    width: 100%;

}

.searchbar-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.searchbar-input {
    width: 100%;
    padding: 8px 16px 8px 40px;
    /* Place l'icône de recherche à gauche */
    border-radius: 50px;
    background-color: var(--light-gray);
}

/* Icône de recherche */
.searchbar-icon {
    position: absolute;
    left: 16px;
}

/* --- Bouton "Go to" avec deux lignes distinctes --- */
.button-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Alignement gauche */
    margin-left: 25px;
    cursor: pointer;

}

.button-content>div {
    display: flex;
    align-items: center;

    /* Aligne "Office" avec l'icône sur la même ligne */
}

.button-content svg {
    margin-left: 4px;
    /* Espacement entre le texte et l'icône */
}

.text-sm {
    font-size: 14px;
}

.font-bold {
    font-weight: bold;
}

.font-normal {
    font-weight: normal;
}

button.bg-transparent {
    background: transparent;
    /* Pas de fond */
    border: none;
    /* Pas de bordure */
    padding: 0;
    margin-right: 16px;
    /* Ajuster selon les besoins */
}

/* --- Section du compte (Account) avec liens en dessous --- */
.account-section {
    display: flex;
    flex-direction: column;
    /* Aligner verticalement */


}

.account-title {
    align-items: flex-start;
    /* Aligner à gauche */
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    /* Espacement entre le titre et les liens */
}

.ml-2 {
    display: flex;
    align-items: center;


}

.account-link {
    display: flex;
    align-items: flex-start;
    color: var(--gray);
    margin-right: 0px;
    /* Réduire l'espacement entre les liens */
    text-decoration: underline;
    cursor: pointer;

}

/* Texte entre les liens (comme "or") */
.or-text {
    font-size: 14px;
    margin-left: 0 4px;
    /* Réduire l'espacement autour du texte "or" */
}

/* --- Icône du panier --- */
.cart-button {
    position: relative;
}

.cart-icon {
    width: 24px;
    height: 24px;
}

/* Réinitialisation générale pour les boutons et liens */
.account-link,
.Address_link,
.cart-button .TopLine_delivery {
    background: none;
    /* Pas de fond */
    border: none;
    /* Pas de bordure */
}

.mr-4 {

    background: none;
    /* Pas de fond */
    border: none;
    /* Pas de bordure */
    margin-top: 25px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.cart-button {
    position: relative;
    background: none;
    /* Pas de fond */
    border: none;
    /* Pas de bord */
    cursor: pointer;
}

.Cart_cart_counter__3IczK {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;

    transform: translateX(20%);
    padding: 0 0.25rem;
    width: max-content;
    min-width: 1rem;
    height: 100%;
    max-height: 1rem;
    background-color: #298321;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-family: var(--font-circular-std), sans-serif;
    font-size: 0.6875rem;
    line-height: 1.2;
    font-weight: 700;
}


@media (max-width: 992px) {


    .TopLine_logo_wrapper {
        display: flex;
        justify-content: flex-start;
        margin-right: 25px;
    }

   

    .searchbar-form {
        width: 100%;

    }

}

.delivery-section {

    display: flex;
    align-items: center;
}

.delivery-icon {
    margin-left: 30px;
    /* Espace entre le texte et l'icône */
}

@media (min-width: 992px) {
    .TopLine_delivery {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 25px;

    }


    .TopLine_delivery_title {
        font-size: 16px;
        font-weight: bold;
        color: black;

        /* Espacement entre le texte et le bouton */
    }

    .Address_link {
        font-size: 16px;
        text-decoration: underline;
        /* Souligne le bouton */

        /* Pas de fond */
        color: red;
        /* Couleur du texte */
    }

}