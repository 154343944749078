/* Container pour les détails du produit */
.Container {
 
  display: flex;
  flex-direction: column;
  
}

/* Nom du produit */
.productName {
  font-size: 34px;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}





.productInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

/* Taille unitaire */
.unitSize {
  font-size: 14px;
  margin-right: 10px;
  color: black;
}

/* Prix original barré */
.originalPrice {
  font-size: 14px;
  text-decoration: line-through;
  color: #aaa;
  margin-right: 5px;
}

/* Prix actuel */
.price {
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-right: 10px;
}

/* Conteneur pour les labels ShopBy */
.shopByContainer {
  margin: 10px 0;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.shopByLabel {
  font-size: 14px;
  color: black;
}

/* Conteneur pour la quantité */
.quantityContainer {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  gap: 10px;
}
.discount-red {
  color: red;
}


.addToCartWithQuantity {
  display: flex;
  align-items: center;
  /*dding: 10px 100px;*/
  width: 250px;
  border: 1px solid #ccc;
  padding:15px;
  overflow: hidden;
}

.quantityButton {
  flex: 1;
  background-color: white;
  border: none;
  
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantityButtonLeft {
  text-align: left;
 
}

.quantityButtonRight {
  text-align: right;
 
}

.quantityText {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

/* Bouton Description et Nutrition */
.descriptionButton,
.nutritionButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.descriptionButton:hover,
.nutritionButton:hover {
  background-color: #0056b3;
}

/* Conteneur pour l'image du produit */
.productImageContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}



/* Modal */
.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent1 {
  background: white;
  padding: 20px;

  width: 80%;

  text-align: center;
}

.modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.modalCloseButton:hover {
  color: #000;
}

.productHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  /* Espacement entre les colonnes */
  margin-bottom: 20px;
}



.quantityAndInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}



.detailsButton1 {
  display: none;

}
.detailsButton2 {
  color: black;
  font-size: 14px;
  /* Ajouté px pour spécifier l'unité */
  font-weight: bold;
  /* Correction de la syntaxe */
  margin-top: 4px;
  padding: 20px 100px;
  background-color: white;
  border: 1px solid #ccc;

}

.detailsButton {
  color: black;
  font-size: 14px;
  /* Ajouté px pour spécifier l'unité */
  font-weight: bold;
  /* Correction de la syntaxe */
  margin-top: 4px;
  padding: 20px 110px;
  background-color: white;
  border: 1px solid #ccc;

}

/* Existing styles remain the same, with these modifications: */

.productImageColumn {

  flex: 0.75;
  display: flex;
  justify-content: flex-start;
  /* Align image to the left */
  align-items: center;
}

.productImageColumn img {
  margin-top: 5px;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  /* Ensures image maintains its aspect ratio */
  margin-right: auto;
  /* Pushes the image to the left */
}

.addToCartButton {
  background-color: #298321;
padding: 12px;
  /* Increased horizontal padding */
  border-radius: 40px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  width: 300px;
  /* Fixed width for the button */
}

.qantityControl{
  border-color: #333;
  border-radius: 4px;
  background-color: white;
  padding: 10px 30px;
  width: 300px;
}

/* Responsive Description Container */
.descriptionContainer {
  margin-top: 20px;
  width: 50%;
}

.descriptionContainer h3 {
  margin-bottom: 10px;
  color: #333;
}

/* Media query to ensure mobile-specific styles */
@media (max-width: 992px) {
  .productHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
.detailsButton2 {
  display: none;
}
  .productDetailsColumn {
    grid-column: 1;
    width: 100%;
  }

  .productImageColumn {
    grid-column: 2;
    width: 100%;
  }

  .quantityAndInfoContainer {
    grid-column: 1;
    flex-direction: column;
    width: 100%;
  }

  .nutritionButtonColumn {
    margin-top: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 50%;
}

.quantityContainer{
  width: 100%;
}
  .detailsButton,
  .detailsButton1 {
    width: calc(50% - 10px);
   
    display: inline-block;
    color: black;
  font-size: 14px;
  /* Ajouté px pour spécifier l'unité */
  font-weight: bold;
 
  margin-top: 4px;
  padding: 20px 110px;
  background-color: white;
  border: 1px solid #ccc;
  }

  .nutritionButtonColumn {
    display: inline-block;
    width: calc(50% - 10px);
    margin-left: 20px;
  }
}

@media screen and (max-width: 748px) {
  .productHeader {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .detailsButton2 {
    display: none;
  }
  .productDetailsColumn {
    grid-column: 1;
    order: 1;
  }

  .productImageColumn {
    grid-column: 1;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .productImageColumn img {
    max-width: 80%;
    margin: 0 auto;
  }

  .quantityAndInfoContainer {
    grid-column: 1;
    order: 3;
  }

  .nutritionButtonColumn {
    margin-top: 0;
    margin-left: 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }

  .detailsButton,
.detailsButton1 {
   
    display: inline-block;
    color: black;
    font-size: 14px;
    font-weight: bold;
    justify-content: center; /* Centrer horizontalement */
    align-items: center; /* Centrer verticalement */
   
    text-align: center;
    padding: 20px 50px;
    background-color: white;
    border: 1px solid #ccc;
}

.quantityAndInfoColumn{
  width: 100%;
}
  .nutritionButtonColumn {
    width: auto;
    display: block;
  }

  .quantityContainer {
    width: 100%;
  }

  .addToCartButton,
  .addToCartWithQuantity {
    width: 100%;
    max-width: none;
  }
}