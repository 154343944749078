/* Overlay background */
.modal-overlay {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Sidebar styles */
.cart-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.cart-sidebar.slide-in {
  transform: translateX(0);
}

.cart-sidebar.slide-out {
  transform: translateX(100%);
}

/* Section 1: Header */
.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.cart-header h2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.close-button {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

/* Section 2: Contenu */
.cart-body {
  padding: 1rem;
  background-color: #f5f5f5; /* Fond gris clair */
  height: calc(100% - 70px); /* Ajuste la hauteur en fonction de l'en-tête */
  overflow-y: auto;
}

.empty-cart {
  text-align: center;
}

.empty-cart h1 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.empty-cart span {
  font-size: 0.875rem;
  color: #666;
}

/* Styles pour les éléments du panier */
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  
}

.cart-item span {
  font-size: 1rem;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
  font-size: 0.875rem;
}

/* Click-outside area */
.click-outside {
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
}




.cart-product-item {
  display: flex; /* Utilise Flexbox pour la mise en page */
  align-items: flex-start; /* Aligne les éléments au début */
  margin-bottom: 20px; /* Espacement entre les éléments du panier */
}
.cart-product-item:not(:last-child) {
  border-bottom: 5px solid #fff; /* Trait blanc entre les produits, sauf le dernier */
}

.cart-product-image {
  width: 100px; /* Ajustez la largeur de l'image selon vos besoins */
 
  margin-right: 30px; /* Espace entre l'image et les détails */
}

.cart-product-details {
  display: flex;
  flex-direction: column; /* Aligne les éléments de détail en colonne */
 
  
}

.cart-vertical-line {
  width: 1px;
  height: 20px; /* Ajustez selon vos besoins */
  background-color: #ccc; /* Couleur de la ligne verticale */
  margin: 0 10px; /* Espacement autour de la ligne */
}
.price-quantity-container {
  display: flex;
  align-items: center; /* Aligne les éléments verticalement */
}

.cart-current-price {
  margin-right: 10px; /* Espacement à droite du prix */
}
.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000; /* Couleur de l'icône */
}
/* Autres styles pour le cart-body, cart-header, etc. peuvent rester les mêmes */

@media (max-width: 768px) {
  .cart-sidebar {
    width: 100%; /* La barre latérale prend toute la largeur de l'écran */
    height: 100vh; /* Prend toute la hauteur de l'écran */
  }

  .product {
    display: flex;
    flex-direction: column; /* Une colonne pour chaque produit */
  }

  .cart-product-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   
    padding: 15px 0;
  }

  .cart-product-item:nth-child(2n) {
    border-right: none; /* Pas de ligne à droite pour les colonnes de droite */
  }
  

  .cart-product-details {
    display: flex;
    flex-direction: column; /* Aligne les détails en colonne */
    width: 100%; /* Prend toute la largeur disponible */
  }

  .price-quantity-container {
    display: flex;
    justify-content: space-between; /* Espace entre le prix et le sélecteur de quantité */
    width: 100%;
    margin-top: 10px; /* Espace pour séparer cette section des autres détails */
  }
  

  .cart-current-price {
    font-weight: bold;
  }
}
/* Cart.css */
.remove-item-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.remove-item-button:hover {
  background-color: #e60000;
}

