/* GiveawayComponent.css */
.giveaway {
    margin-top: 50;
    padding: 15px;

    border-bottom: none;
    border-top: none;
    margin-top: 20px;
    font-family: Arial;
    text-align: center;
    background-color:var(--vert) !important;
}

.giveaway-title {
    color: #ffffff;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.giveaway-link {
    text-decoration: underline;
    color: #FFFFFF;
    font-weight: bold; /* Pour le lien */
}

.claimed-message {
    color: #ffffff;
    font-weight: normal;
}
@media (max-width: 992px) {
    .giveaway {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 0px;
    }
  
  }
  