.promotions-2 {
    margin: 0 auto; /* Centre le conteneur horizontalement */
    max-width: 100%; /* Assure que le conteneur ne dépasse pas la largeur de l'écran */
    padding: 10px 10px; /* Ajoute un espace de 10px à gauche et à droite */
    overflow: hidden;
    position: relative;
    background-color: white;
    width: calc(100% - 20px); /* Réduit la largeur totale pour inclure les marges */
}

.promo-media-2 {
    width: 100%; /* Prend toute la largeur du conteneur */
    height: auto; /* Hauteur automatique pour respecter le ratio */
    max-height: 450px; /* Hauteur maximale pour grand écran */
    object-fit: scale-down; /* Adapte l'image sans la rogner */
   
 
}

@media (max-width: 992px) {
    .promotions-2 {
        padding: 20px 10px; /* Augmente les marges sur mobile */
       
    }

    .promo-media-2 {
        object-fit: cover; /* Couvre l'espace disponible sans déformer l'image */
        
    }
}
