/* DeliveryOptions.css */

.delivery-options-container {
    max-width: 600px; /* Ajustez selon vos besoins */
    margin: 0 auto;
    
}

.delivery-options-title {
   
    font-weight: 500; /* Medium */
    color: #444; /* Gray-900 */
    
}



.delivery-options-list {
    display: flex;
   
}

.delivery-option-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Espace entre les options */
}

.delivery-option-input {
    color: #444; /* Gray-900 */
    margin-right: 10px; /* Espace entre l'input et le label */
}

.delivery-option-label {
    color: #444; /* Gray-700 */
}
/* Ajoutez ceci à votre fichier CSS existant */

.privacy-policy-button,
.customer-agreement-button {
    background: none; /* Pas de fond */
    border: none; /* Pas de bordure */
    color: #3b82f6; /* Couleur bleue (blue-600) */
    cursor: pointer; /* Changer le curseur au survol */
    text-decoration: none; /* Pas de soulignement */
    padding: 0; /* Pas de remplissage */
    
}

.privacy-policy-button:hover,
.customer-agreement-button:hover {
    color: var(--primary-color); /* Couleur bleue plus foncée au survol (blue-800) */
    
}

.text-center {
    text-align: center; /* Centrer le texte */
}
.form-container {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */
    justify-content: space-between;
}

.form {
    width: 48%; /* Chaque élément prendra 48% de la largeur, laissant de l'espace entre eux */
    margin-bottom: 16px;
}

.form label {
    display: block;
    margin-bottom: 8px;
}

.form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;
}

@media (max-width: 768px) {
    .form {
        width: 100%; /* Pour les petits écrans, les éléments occupent toute la largeur */
    }
}
