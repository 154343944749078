.subcategories-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 colonnes */
  gap: 30px; /* Espace entre les colonnes */
  margin-bottom: 40px; /* Supprime les marges pour prendre toute la largeur */
  padding: 10px; /* Ajoutez du padding si nécessaire */
  width: 100vw; /* Prendre toute la largeur de la fenêtre */
  box-sizing: border-box; /* Inclut le padding dans la largeur totale */
}

.subcategories-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: contents; /* Pour que les éléments de la liste prennent l'espace du grid */
}

.subcategories-container .subcategory-link {
  background: none; /* Supprime le fond */
  border: none; /* Supprime la bordure */
  text-decoration: none; /* Supprime le soulignement */
  color: var(--dark-gray); /* Couleur du texte */
  font-weight: bold;
  transition: color 0.3s ease; /* Transition pour l'effet de survol */
  padding: 10px; /* Ajoutez du padding si nécessaire */
  text-align: center; /* Centrer le texte si nécessaire */
  cursor: pointer; /* Change le curseur en pointeur pour indiquer que c'est cliquable */
}

.subcategories-container .subcategory-link:hover {
  color: green; /* Change la couleur au survol */
}
